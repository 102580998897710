import React from 'react'
import { responsiveTitle1 } from '../typography.module.css'
import PortableText from '../portableText'
import { intro } from './management.module.css'
import { Link } from 'gatsby'
const MembershipPage = ({ portableText }) => {
  return (
    <article className={intro}>
      <h1 className={responsiveTitle1}>العضوية</h1>
      <main style={{ maxWidth: '67ch' }}>
        <PortableText blocks={portableText} />
      </main>
      <Link to={`/registration/membership-reg`}>نموذج طلب العضوية</Link>
    </article>
  )
}
export default MembershipPage
