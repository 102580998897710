import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Layout from '../containers/layout'
import Container from '../components/container'
import SEO from '../components/seo'
import Breadcrumb from '../components/layout/breadcrumb'
import MembershipPage from '../components/pages/membership'
import AstorynomyStandardsPage from '../components/pages/astronomyStandards'

const query = graphql`
  query astronomyStandardsPage {
    astronomyStandards: sanityManagement(_id: { regex: "/(drafts.|)management/" }) {
      _rawQasStandards
    }
  }
`

const QasStandards = props => {
  let navItemStack = [{ name: 'المعايير الفلكية', slug: 'qas-standards' }]
  const astronomyStandards = useStaticQuery(query)
  const { _rawQasStandards } = astronomyStandards.astronomyStandards || {}
  return (
    <Layout>
      <SEO title="المعايير الفلكية لجمعية الفلك بالقطيف" />
      <Container>
        <Breadcrumb currentNavItems={navItemStack} />
        <AstorynomyStandardsPage portableText={_rawQasStandards} />
      </Container>
    </Layout>
  )
}
export default QasStandards
