import React from 'react'
import { responsiveTitle1 } from '../typography.module.css'
import PortableText from '../portableText'
import { intro } from './management.module.css'
const AstorynomyStandardsPage = ({ portableText }) => {
  return (
    <article className={intro}>
      <h1 className={responsiveTitle1}>المعايير الفلكية لجمعية الفلك بالقطيف</h1>
      <main>
        <PortableText blocks={portableText} />
      </main>
    </article>
  )
}
export default AstorynomyStandardsPage
